import startsWith from 'lodash/startsWith'
import trimEnd from 'lodash/trimEnd'
import trimStart from 'lodash/trimStart'
import isNil from 'lodash/isNil'
import moment from 'moment'

moment.locale('vi')

export function easyIdAvatar(userId) {
  return process.env.VUE_APP_BASE_MEDIA + '/easy_id/avatars/' + userId + '.jpg'
}

/**
 * Get full media url
 * @param {String} uri
 */
export function media(uri, host) {
  const mediaHost = host || process.env.VUE_APP_BASE_MEDIA
  if (!startsWith(uri, 'http')) {
    uri = trimEnd(mediaHost, '/') + '/' + trimStart(uri, '/')
  }
  return uri
}

export function googleDriveMedia(file) {
  if (startsWith(file.mimeType, 'audio') || startsWith(file.mimeType, 'image')) {
    return `https://drive.google.com/uc?id=${file.id}`
  }

  const link = file.webViewLink || file.url || file || ''

  return link.replace('/view', '/preview')
}

export function schoolMediaLink(id) {
  if (id && (typeof id === 'object' && id.link !== null)) {
    return id.link.webUrl
  }
  if (!id || (typeof id === 'string' && id.includes('/'))) {
    return id
  }

  return `https://drive.google.com/file/d/${typeof id === 'string' ? id : id.id}`
}

export function googleDriveQuestionMedia(id) {
  if (!id.includes('/')) {
    return `https://drive.google.com/uc?id=${id}`
  } else {
    const mediaHost = process.env.VUE_APP_BASE_MEDIA
    if (!startsWith(id, 'http')) {
      id = trimEnd(mediaHost, '/') + '/' + trimStart(id, '/')
    }
    return id
  }
}

export function answerGoogleDriveMedia(id) {
  if (id && id.includes('&ggdrive')) {
    return `https://drive.google.com/file/d/${id.split('&ggdrive')[0]}`
  }

  return id
}

export function dateF(time) {
  if (!time) {
    return '-'
  }
  return moment(time).format('DD/MM/YYYY')
}

export function dateTime(time) {
  if (!time) {
    return ''
  }

  return moment(time).format('HH:mm DD/MM')
}

export function roleFilter(val) {
  const map = {
    super_admin: 'SUPER_ADMIN',
    admin: 'ADMINISTRATOR',
    teacher: 'LECTURER'
  }

  return map[val]
}

export function gender(val) {
  const map = [
    'Nam',
    'Nữ',
    'Khác'
  ]

  if (isNil(val)) {
    return '-'
  }

  return map[val]
}

export function dateWithWeekDay(time) {
  if (time && time !== '') {
    moment.locale('vi')
    const date = moment(time).clone()
    const date1 = moment(time).clone()
    date1.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const date2 = moment({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const difference = date2.diff(date1, 'days')
    if (difference !== 1 && difference !== 0 && difference !== -1) {
      return date.format('dddd') + ', ' + date.format('DD/MM/YYYY HH:mm')
    } else if (difference === 0) {
      return 'hôm nay, ' + date.format('DD/MM/YYYY HH:mm')
    } else if (difference === 1) {
      return 'hôm qua, ' + date.format('DD/MM/YYYY HH:mm')
    } else {
      return 'ngày mai, ' + date.format('DD/MM/YYYY HH:mm')
    }
  } else {
    return ''
  }
}

export function timeAgo(time) {
  return moment(time).fromNow(true) + ' trước'
}

export function vnd(num) {
  if (!num || num === 0) {
    return 'Miễn phí'
  }
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ',')) + ' VNĐ'
}

export function formatNum(num) {
  if (!num || num === 0) {
    return '0'
  }
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

export function formatNumWidthDot(num) {
  if (!num || num === 0) {
    return '0'
  }
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, '.'))
}

export function homeworkEndedAt(date) {
  const now = moment()
  const time = moment(date)
  const delta = now.clone().unix() - time.clone().unix()
  const days = Math.trunc(delta / (60 * 60 * 24))

  if (delta < 0) {
    return 'Đã hết hạn'
  }

  if (days === 0) {
    const hours = Math.trunc(delta / (60 * 60))
    if (hours === 0) {
      return 'Còn ít hơn 1h'
    }

    return `Còn ${hours} giờ`
  }

  if (time.isBefore(now)) {
    return `Quá hạn ${days} ngày`
  }

  return `Còn ${days} ngày`
}

export function doubleToFixed(num) {
  if (!num) {
    return 0
  }
  return num.toFixed(2)
}

export function integerToFixed(num) {
  if (!num) {
    return 0
  }
  return num.toFixed(0)
}

export function gradeFilter(val) {
  const map = {
    T34: '3-4 tuổi',
    T45: '4-5 tuổi',
    T56: '5-6 tuổi',
    T67: '6-7 tuổi',
    T78: '7-8 tuổi',
    L1: 'Khối 1',
    L2: 'Khối 2',
    L3: 'Khối 3',
    L4: 'Khối 4',
    L5: 'Khối 5',
    L6: 'Khối 6',
    L7: 'Khối 7',
    L8: 'Khối 8',
    L9: 'Khối 9',
    L10: 'Khối 10',
    L11: 'Khối 11',
    L12: 'Khối 12',
    ĐH: 'Đại học'
  }

  return map[val]
}

export function memorySize(a) {
  if (a === 0 || !a) {
    return '-'
  }

  const b = 2
  const c = 1024
  const d = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const e = Math.floor(Math.log(a) / Math.log(c))
  return parseFloat((a / Math.pow(c, e)).toFixed(b)) + ' ' + d[e]
}

// Calculate time in seconds to display full hh:mm:ss
export function timeCalculator(time) {
  if (!time) {
    return '00:00:00'
  }

  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time - hours * 3600) / 60)
  const seconds = time - hours * 3600 - minutes * 60

  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes
  }:${seconds < 10 ? '0' + seconds : seconds}`
}

export function questionTypeFilter(t) {
  const map = {
    MultiChoice: 'MULTICHOICE',
    ShortAnswer: 'SHORTANSWER',
    StringSorting: 'STRINGSORTING',
    Classification: 'CLASSIFICATION',
    Speaking: 'SPEAKING',
    UnderlineWord: 'UNDERLINEWORD',
    Matching: 'MATCHING',
    WordSearch: 'WORDSEARCH'
  }

  return map[t]
}

export function randomKey(prefix) {
  return `${prefix}-` + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
}

export function rtContestQuestionTypeFilter(t) {
  const map = {
    MultiChoice: 'Trắc nghiệm',
    ShortAnswer: 'Điền đáp án đúng',
    StringSorting: 'Sắp xếp chữ cái, từ',
    Classification: 'Phân loại',
    Speaking: 'Phát âm',
    UnderlineWord: 'Gạch chân',
    Matching: 'Nối đáp án đúng',
    WordSearch: 'Tìm ô chữ'
  }

  return map[t]
}

export function tRcvStatusFilter(t) {
  const map = {
    not_start: 'Chưa bắt đầu',
    prepare: 'Chuẩn bị thi',
    in_progress: 'Đang thi',
    ended: 'Đã kết thúc'
  }

  return map[t]
}

export function rankIndexFilter(rank) {
  if (rank === 0 || !rank) {
    return '500+'
  }

  return rank
}

export function formatRewardCategory(category) {
  const map = {
    'e-coin': 'E-Coin',
    item: 'Vật phẩm',
    'easy-course': 'Khóa học'
  }

  return map[category]
}
