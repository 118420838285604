import request from '@/utils/request'
import serialize from '@/utils/serialize'

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}

export function logout(data) {
  return request({
    url: '/account/logout',
    method: 'get'
  })
}

export function register(data) {
  return request({
    url: '/auth/register',
    method: 'post',
    data
  })
}

export function changePassword(data) {
  return request({
    url: '/account/password',
    method: 'put',
    data
  })
}

export function update(data) {
  return request({
    url: '/account',
    method: 'put',
    data
  })
}

export function getProfile() {
  return request({
    url: '/account',
    method: 'get'
  })
}

export function linkAccountWithEasyId(data) {
  return request({
    url: '/account/link-easy-id',
    method: 'put',
    data
  })
}

export function grantEasyMentorAccessToken() {
  return request({
    url: '/account/grant-easy-mentor-token',
    method: 'post'
  })
}

export function listTeacher(data) {
  return request({
    url: '/teacher' + serialize(data),
    method: 'get'
  })
}

export function createTeacher(data) {
  return request({
    url: '/teacher',
    method: 'post',
    data
  })
}

export function resetPassword(data) {
  return request({
    url: '/teacher/reset-password',
    method: 'post',
    data
  })
}

export function updateTeacher(data) {
  return request({
    url: '/teacher',
    method: 'put',
    data
  })
}

export function changeAvatar(data) {
  const upload = new FormData()
  upload.append('file', data.file)

  return request({
    url: '/account/avatar',
    method: 'put',
    timeout: false,
    data: upload,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
